import { useState } from "react";
import BannerCard from "@/components/ui/banner-card";
import Slick from "@/components/ui/carousel/slick";
const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    {
      title: "A Path to Social Safety for Migrant Workers",
      description:
        "Watch Ashif Shaikh, the convener of MRC, share the idea behind our work and how we aim to work with governments, industry, and civil society to support those who build our world.",
      image: "/images/home/ted-banner.webp",
      buttonLink:
        "https://www.ted.com/talks/ashif_shaikh_a_path_to_social_safety_for_migrant_workers?hasSummary=true",
    },
    // {
    //   title: "Migrants Resilience Collaborative",
    //   description:
    //     "Read our new research surveying 25,000 workers across 5 countries on the impact of climate change",
    //   image: "/images/home/home_banner_02.webp",
    //   buttonLink: "#",
    // },
    // {
    //   title: "A Path to Social Safety for Migrant Workers",
    //   description:
    //     "Watch Ashif Shaikh, the convener of MRC, share the idea behind our work and how we aim to work with governments, industry, and civil society to support those who build our world.",
    //   image: "/images/home/ted-banner.jpg",
    //   buttonLink:
    //     "https://www.ted.com/talks/ashif_shaikh_a_path_to_social_safety_for_migrant_workers?hasSummary=true",
    //   buttonText: "Watch The Talk",
    // },
  ];
  var responsive = [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 479,
      settings: {
        slidesToShow: 1,
      },
    },
  ];
  return (
    <div className="homeBanner relative mb-0 mx-auto overflow-hidden bg-white w-full">
      <Slick
        dots={false}
        navigation={false}
        responsive={responsive}
        slidesToShow={1}
        fade={true}
        onSlideChange={setCurrentSlide}
      >
        {data?.map((row, key) => (
          <BannerCard
            banner={row}
            isActive={currentSlide === key}
            key={"homeBanner" + key}
          />
        ))}
      </Slick>
    </div>
  );
};

export default Banner;
